import { Route, Routes, Navigate } from "react-router-dom"
import Main from "./components/Main";
import Mint from "./components/Mint";
import 'swiper/css'
import './assets/css/style.css';

const App = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Main />
          }
        />
        <Route
          path="/mint-jumpers"
          element={
            <Mint />
          }
        />
        <Route path="*" element={<Navigate to={{ pathname: "/" }} />} />
      </Routes>
    </div>
  );
}

export default App;
