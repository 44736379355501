import loader from '../assets/images/loader.gif'

const Loader = () => {
    return (
        <div className='absolute w-full h-screen flex justify-center items-center loader'>
            <img className='' src={loader}></img>
        </div>
    )
}

export default Loader