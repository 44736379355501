import { Header } from './Header'
import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from 'swiper/modules';
import img_another_space from '../assets/images/another-space.png'

import img_btn_minus from '../assets/images/btn-minus.png'
import img_btn_plus from '../assets/images/btn-plus.png'
import img_avatar1 from '../assets/images/avatars/avatars1.png'
import img_avatar2 from '../assets/images/avatars/avatars2.png'
import img_avatar3 from '../assets/images/avatars/avatars3.png'
import img_avatar4 from '../assets/images/avatars/avatars4.png'
import img_avatar5 from '../assets/images/avatars/avatars5.png'
import img_avatar6 from '../assets/images/avatars/avatars6.png'
import img_avatar7 from '../assets/images/avatars/avatars7.png'
import img_avatar8 from '../assets/images/avatars/avatars8.png'
import img_avatar9 from '../assets/images/avatars/avatars9.png'
import img_avatar10 from '../assets/images/avatars/avatars10.png'

const Mint = () => {

    const [mintCount, setMintCount] = useState(2)

    useEffect(() => {
        document.body.style.overflowY = "auto"
    }, [])

    return (
        <>
            <Header />
            <div className='mint-container w-full flex items-center justify-between p-4'>
                <div className='mint-page m-auto'>
                    <div className="md:flex">
                        <div className="md:w-2/5">
                            <img className='mt-8' src={img_another_space}></img>
                            <Swiper className="md:-mt-10" autoplay={{ delay: 2000 }} modules={[Autoplay]}>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-4 rounded-sm' src={img_avatar1}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar2}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar3}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar4}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar5}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar6}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar7}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar8}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar9}></img></SwiperSlide>
                                <SwiperSlide><img className='mt-8 md:mt-20 border-white border-2 rounded-sm' src={img_avatar10}></img></SwiperSlide>
                            </Swiper>
                        </div>
                        <div className='md:w-1/2 md:ml-6 relative'>
                            <div className='flex justify-center'>
                                <div className='border-white mt-20 border-4 uppercase font-bold rounded-full text-white text-xl pl-4 pr-4 bg-pink cursor-pointer font-bangers'>connect wallet</div>
                            </div>
                            <div className='text-white text-7xl uppercase text-center mt-10 font-bangers mb-6'>
                                NFT Crew mint <span className='text-color-yellow'>is live!</span>
                            </div>
                            <div className='text-white font-nanum text-3xl text-center w-5/6 m-auto'>
                                ASO MAIN CHARACTERS NFTS are the ticket to unlock the Loop and join the adventure!
                            </div>
                            <div className='border-white border-4 uppercase w-5/6 m-auto mt-6 rounded-2xl font-bangers text-white text-xl p-1 bg-purple cursor-pointer'>
                                <div className='flex'>
                                    <div className='w-1/3 text-center'>
                                        <div className='border-l-0 border-t-0 border-b-0 border-r-white border-2'>Supply</div>
                                        <div>3333/3333</div>
                                    </div>
                                    <div className='w-1/3 text-center'>
                                        <div className='border-l-0 border-t-0 border-b-0 border-r-white border-2'>Minted</div>
                                        <div>300%</div>
                                    </div>
                                    <div className='w-1/3 text-center'>
                                        <div>Prize</div>
                                        <div>1 AVAX</div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex m-auto mt-8 w-5/6 justify-between'>
                                <div className='cursor-pointer w-10 md:w-auto' onClick={() => {
                                    if (mintCount > 1) setMintCount(mintCount - 1)
                                }}>
                                    <img src={img_btn_minus}></img>
                                </div>
                                <div className='text-white text-lg md:text-2xl pt-0 md:pt-2 italic font-bold'>
                                    ASO HEROES X <span className='text-pink-600'>{mintCount}</span>
                                </div>
                                <div className='cursor-pointer w-10 md:w-auto' onClick={() => {
                                    setMintCount(mintCount + 1)
                                }}>
                                    <img className='' src={img_btn_plus}></img>
                                </div>
                            </div>
                            {/* <div className='end text-2xl text-right text-white pr-12 mt-4 font-bangers'>
                            MAX MINT
                        </div> */}
                            <div className='flex justify-around text-2xl text-white mt-6 mb-10 italic font-bold'>
                                <div>
                                    TOTAL
                                </div>
                                <div>
                                    {mintCount} AVAX+GAS
                                </div>
                            </div>
                            <div className='border-white border-4 uppercase block lg:absolute bottom-0 w-5/6 btn-mint m-auto text-center font-bold rounded-full text-white text-4xl p-1 bg-pink cursor-pointer font-bangers'>MINT NOW!</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mint