import { useEffect, useState, useRef } from 'react';
// import Web3 from 'web3';
import { useMetaMask } from "metamask-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faDiscord, faCircleStop } from '@fortawesome/free-brands-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
// import { SELECT_ABI, ELITE_ABI } from './util/abi';
// import { getWeb3 } from './util/wallet';
import { pinJSONToIPFS } from '../util/pinata';
import 'react-toastify/dist/ReactToastify.css';
import img_home_bg from '../assets/images/home-bg.png';
import img_home_btn_bg from '../assets/images/home-button-bg.png';
import img_space_chicken from '../assets/images/space-chicken.png';
import img_aso from '../assets/images/astro.png';
import img_aso_mid from '../assets/images/astro-mid.png';
import img_all from '../assets/images/all.png';
import img_all_sm from '../assets/images/all-sm.png';
import img_all_mid from '../assets/images/all-mid.png';
import img_avatar from '../assets/images/avatar.png';
import img_word from '../assets/images/word.png';
import img_blackhole from '../assets/images/black-hole.png';
import img_phase1_title_bg from '../assets/images/phase1-title-bg.png';
import img_phase2_title_bg from '../assets/images/phase2-title-bg.png';
import img_features1 from '../assets/images/features1.png';
import img_features2 from '../assets/images/features2.png';
import img_join_btn_bg from '../assets/images/home-join-button-bg.png';
import img_crew_title1 from '../assets/images/crew-title-1.png';
import img_crew_title2 from '../assets/images/crew-title-2.png';
import img_chicken from '../assets/images/chicken.png';
import img_gem from '../assets/images/gem.png';
import img_logo_white from '../assets/images/logo-white.png'
import img_twitter from '../assets/images/twitter.png';
import img_discord from '../assets/images/discord.png';
import img_telegram from '../assets/images/telegram.png';
import img_roadmap_bg from '../assets/images/roadmap-title-bg.png'
import img_another_space from '../assets/images/another-space.png'
import img_stop from '../assets/images/stop.svg'
import img_pause from '../assets/images/pause.svg'
import img_parallax1_bg from '../assets/images/parallax1/background.png'
import img_parallax1_chicken from '../assets/images/parallax1/chicken.png'
import img_parallax1_spiral from '../assets/images/parallax1/spiral.png'
import img_parallax1_asteroid1 from '../assets/images/parallax1/asteroid1.png'
import img_parallax1_asteroid2 from '../assets/images/parallax1/asteroid2.png'
import img_parallax1_asteroid3 from '../assets/images/parallax1/asteroid3.png'
import img_parallax1_asteroid4 from '../assets/images/parallax1/asteroid4.png'
import img_parallax1_planet1 from '../assets/images/parallax1/planet1.png'
import img_parallax1_planet2 from '../assets/images/parallax1/planet2.png'
import img_parallax1_planet3 from '../assets/images/parallax1/planet3.png'
import img_parallax2_bg from '../assets/images/parallax2/background.png'
import img_parallax2_layer from '../assets/images/parallax2/layer.png'
import img_parallax2_pilot from '../assets/images/parallax2/pilot.png'
import img_parallax2_still_bg from '../assets/images/parallax2/bg-mid.png'
import img_parallax2_still_sm from '../assets/images/parallax2/bg-sm.png'
import img_phase1_content_bg from '../assets/images/phase1-bg.png'
import img_phase2_content_bg from '../assets/images/phase2-bg.png'
import img_preview from '../assets/images/preview.png'
import img_preview_trailer from '../assets/images/preview_trailer.png'
import img_havoc from '../assets/images/havoc.jpg'
import img_parallax2_cable1 from '../assets/images/parallax2/cable1.png'
import img_parallax2_cable2 from '../assets/images/parallax2/cable2.png'
import img_parallax1_bg_mid from '../assets/images/parallax1/bg-mid.png'
import img_parallax1_bg_sm from '../assets/images/parallax1/bg-sm.png'
import img_ikiag from '../assets/images/ikiag.png'
import img_nft_crew from '../assets/images/first-part-nft-crew.png'
import img_nft_crew_sm from '../assets/images/first-part-nft-crew-small.png'
import whitepaper from '../assets/ASO-Whitepaper.pdf'

import video_comp from '../assets/video/comp.mp4';
import video_comp_mid from '../assets/video/comp-mid.mp4';
import video_comp_sm from '../assets/video/comp-sm.mp4';
import video_trailer from '../assets/video/trailer.mp4';
import { Header } from '../components/Header';
import Loader from '../components/Loader';
import Mint from '../components/Mint';

const Main = () => {

  // const { status, connect, account, chainId, ethereum } = useMetaMask();
  // const [isHoverContactUs, setIsHoverContactUs] = useState(false);
  // const [isVisibleLearnMore, setVisibleLearnMore] = useState(false);
  // const select_mint_price = 0.003;
  // const elite_mint_price = 0.01;

  // const toastId = useRef(null);

  // const launchToast = () => toastId.current = toast(<div className="alert-pending"><img src={img_loading}></img><div>Pending...</div></div>, { autoClose: false });

  // const updateToastToSuccess = (text) => toast.update(toastId.current, {
  //   render: <div>{text}</div>,
  //   type: toast.TYPE.SUCCESS,
  //   autoClose: 5000
  // });

  // const updateToastToError = (text) => toast.update(toastId.current, {
  //   render: <div>{text}</div>,
  //   type: toast.TYPE.ERROR,
  //   autoClose: 5000
  // });

  // useEffect(() => {
  //   console.log('status >>>>>>>>>>', status);
  //   console.log('chainId >>>>>>>>>>', chainId);
  // }, [status, chainId]);

  // const mintNFT = async (token_uri, mint_price, abi, contract_address) => {
  //   try {
  //     const { ethereum } = window;

  //     if (ethereum) {
  //       const web3 = await getWeb3();
  //       const contract = new web3.eth.Contract(abi, contract_address);

  //       if (account == process.env.REACT_APP_CRUISE_OPERATION_WALLET) {
  //         console.log('I am admin');
  //         mint_price = 0;
  //       }

  //       await contract.methods.mint(token_uri)
  //         .send({ from: account, value: mint_price * 1000000000000000000 })
  //         .once("sending", function (payload) {
  //           console.log('PENDING......');
  //         })
  //         .on("confirmation", () => {
  //           console.log('CONFIRM......');
  //           updateToastToSuccess("Successfully minted");
  //         })
  //         .on("error", () => {
  //           updateToastToError("Failed to mint");
  //           console.log('ERROR......');
  //         });

  //     } else {
  //       console.log("Ethereum object doesn't exist")
  //       updateToastToError("Failed to mint");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     updateToastToError("Failed to mint");
  //   }
  // }

  // const jsonUploadSelect = async (level) => {
  //   let result = await pinJSONToIPFS({
  //     "name": "CruiseDAO Select Membership",
  //     "description": `CruiseDAO NFTs unlock access to an exclusive club, offering real-life experiences, utilities, and governance for all. Think of it like a virtual "sea-pass" card to our community utilizing the security of crypto and non-fungible tokens! Our goal is to establish partnerships with the world's best cruise lines, offering an exclusive, first-of-its-kind, NFT powered cruising membership! Welcome to the World’s First Cruising NFT! Select members receive voting rights for community fund allocation, Entrance into “members-only” free cruise giveaways, and other incredible community inspired perks.`,
  //     "external_url": "https://ipfs.io/ipfs/QmVLnURK78S6dDRVziAucfN16dVTt4a7po8wPfnCYKpBHw",
  //     "image": "https://ipfs.io/ipfs/QmVLnURK78S6dDRVziAucfN16dVTt4a7po8wPfnCYKpBHw",
  //     "attributes": [
  //       {
  //         "trait_type": "level",
  //         "value": level
  //       },
  //     ]
  //   });
  //   return result;
  // }

  // const jsonUploadElite = async (level) => {
  //   let result = await pinJSONToIPFS({
  //     "name": "CruiseDAO Elite Membership",
  //     "description": `CruiseDAO NFTs unlock access to an exclusive club, offering real-life experiences, utilities, and governance for all. Think of it like a virtual "sea-pass" card to our community utilizing the security of crypto and non-fungible tokens! Our goal is to establish partnerships with the world's best cruise lines, offering an exclusive, first-of-its-kind, NFT powered cruising membership! Welcome to the World’s First Cruising NFT! Elite members receive 5x voting rights for community fund allocation, Entrance into “members-only” free cruise giveaways, and other incredible community inspired perks. Elite perks will be decided by elite membership.`,
  //     "external_url": "https://ipfs.io/ipfs/QmNsBuheep2oS7LpE6yg9fartjTJzmexegbapgwt2JP7Xf",
  //     "image": "https://ipfs.io/ipfs/QmNsBuheep2oS7LpE6yg9fartjTJzmexegbapgwt2JP7Xf",
  //     "attributes": [
  //       {
  //         "trait_type": "level",
  //         "value": level
  //       }
  //     ]
  //   });
  //   return result;
  // }

  // const getMintIndex = async (abi, contract_address) => {
  //   try {
  //     const { ethereum } = window;
  //     if (ethereum) {
  //       const web3 = await getWeb3();
  //       const contract = new web3.eth.Contract(abi, contract_address);
  //       const mint_index = await contract.methods.getMintIndex().call();
  //       return parseInt(mint_index);
  //     }
  //     return -1;
  //   } catch (error) {
  //     console.log(error)
  //     return -1;
  //   }

  // }

  // const checkNetStatus = () => {
  //   if (status != "connected") {
  //     toast.warn("Please connect Metamask");
  //     return false;
  //   }
  //   if (chainId == "0x4") {
  //     return true;
  //   } else {
  //     toast.warn("Please switch to Rinkeby Network");
  //     return false;
  //   }
  // }

  // const mintSelect = async () => {
  //   let status = checkNetStatus();
  //   if (!status) return false;
  //   launchToast();
  //   let mintIndex = await getMintIndex(SELECT_ABI, process.env.REACT_APP_CONTRACT_SELECT);
  //   if (mintIndex < 0) {
  //     console.log('Failed to get mintIndex');
  //     updateToastToError("Failed to mint NFT");
  //     return false;
  //   }
  //   let json_ipfs = await jsonUploadSelect(mintIndex);
  //   if (!json_ipfs.success) {
  //     console.log(json_ipfs.message);
  //     updateToastToError("Failed to upload metadata to ipfs");
  //     return false;
  //   }
  //   await mintNFT(json_ipfs.ipfsUrl, select_mint_price, SELECT_ABI, process.env.REACT_APP_CONTRACT_SELECT);
  // }

  // const mintElite = async () => {
  //   let status = checkNetStatus();
  //   if (!status) return false;
  //   launchToast();
  //   let mintIndex = await getMintIndex(ELITE_ABI, process.env.REACT_APP_CONTRACT_ELITE);
  //   if (mintIndex < 0) {
  //     console.log('Failed to get mintIndex');
  //     updateToastToError("Failed to mint NFT");
  //     return false;
  //   }
  //   let json_ipfs = await jsonUploadElite(mintIndex);
  //   if (!json_ipfs.success) {
  //     console.log(json_ipfs.message);
  //     updateToastToError("Failed to upload metadata to ipfs");
  //     return false;
  //   }
  //   await mintNFT(json_ipfs.ipfsUrl, elite_mint_price, ELITE_ABI, process.env.REACT_APP_CONTRACT_ELITE);
  // }

  const [isPlaying, setIsPlaying] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [isPageLoaded, setPageLoaded] = useState(false);
  const [isTrailerLoaded, setTrailerLoaded] = useState(false);
  const [phase1Height, setPhase1Height] = useState(0);
  const [phase2Height, setPhase2Height] = useState(0);
  const videoRef = useRef(null);
  const phase1Ref = useRef(null);
  const phase2Ref = useRef(null);
  const [screenWidth, setWidth] = useState(window.innerWidth);
  // const phase1bgRef = useRef(null);
  // const phase2bgRef = useRef(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  const handleStop = () => {
    const video = videoRef.current;
    video.pause();
    video.currentTime = 0;
    setIsPlaying(false);
  };

  const [scrollY1, setScrollY1] = useState(0);
  const [scrollY2, setScrollY2] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + 800 > document.getElementById("parallax1").offsetTop) setScrollY1(window.scrollY + 400 - document.getElementById("parallax1").offsetTop);
      if (window.scrollY + 800 > document.getElementById("parallax2").offsetTop) setScrollY2(window.scrollY + 400 - document.getElementById("parallax2").offsetTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowLoader(false)
    }, 2100);
    return () => {
      clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    setPhase1Height(phase1Ref.current.offsetHeight + 20);
    setPhase2Height(phase2Ref.current.clientHeight + 20);
  }, [phase1Ref, phase2Ref])

  useEffect(() => {
    if (isPageLoaded) document.body.style.overflow = "auto"
  }, [isPageLoaded])

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <div className='relative'>
      {(showLoader || !isPageLoaded) && <Loader />}
      <Header />
      <div className='relative md:h-screen h-full'>
        <video className='h-screen object-cover w-full md:w-full' onLoadedData={() => {
          setPageLoaded(true)
        }} poster={img_preview} autoPlay muted loop playsInline>
          <source src={screenWidth >= 1280 ? video_comp : (screenWidth >= 768 ? video_comp_mid : video_comp_sm)} type='video/mp4' />
        </video>
        <div className='another-space'>
          <img className='w-full' src={img_another_space}></img>
          <div className='narrative'>
            <img src={img_home_btn_bg} className='absolute'></img>
            <div className='absolute'>An nft narrative adventure</div>
          </div>
          <a href='https://discord.gg/gHZabsvcXk' target='_blank'>
            <div className='join'>
              <img src={img_join_btn_bg} className='absolute'></img>
              <div className='absolute'>join now!</div>
            </div>
          </a>
        </div>
        {/* <div className='embark-cosmic-journey'>
            <div>EMBARK ON A THRILLING COSMIC VOYAGE WITH "ANOTHER SPACE ODYSSEY," A GROUNDBREAKING NFT PROJECT OFFERING AN INTERGALACTIC-THEMED ADVENTURE UNLIKE ANY OTHER.</div>
          </div> */}
      </div>
      <div className='video h-fit min-h-screen ' id='trailer'>
        <div className='flex min-h-screen flex-col xl:block'>
          <div className='description flex-1 flex items-center xl:block text-3xl'>
            EMBARK ON AN INTERGALACTIC ODYSSEY featuring a cosmic-themed adventure.
          </div>
          <div className="item flex-1">
            <div className='flex justify-center items-center md:block relative'>
              <video ref={videoRef} controls playsInline onLoadedData={() => {
                // setTrailerLoaded(true)l
              }} poster={img_preview_trailer}>
                <source src={video_trailer} type='video/mp4' />
              </video>
              <div className='video-control-btn'>
                <img onClick={handlePlayPause} className='w-full' src={isPlaying ? img_pause : img_stop}></img>
              </div>
            </div>
          </div>
          {/* <div>
              <button onClick={handlePlayPause}>{isPlaying ? 'Pause' : 'Play'}</button>
              <button onClick={handleStop}>Stop</button>
            </div> */}

          <div className='flex-1 flex items-center'>
            <div className='w-full'>
              <div className='text-join text-4xl md:text-6xl'>
                join the crew...
              </div>
              <div className='text-save text-4xl pr-4 md:text-6xl md:mr-0'>
                and save the universe!
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='space-chicken' id='parallax1'>
        <img className='w-full h-full object-cover bg' src={img_parallax1_bg}></img>
        <img className='w-full h-full object-cover bg-mid' src={img_parallax1_bg_mid}></img>
        <img className='w-full object-cover bg-sm' src={img_parallax1_bg_sm}></img>
        <img className='absolute w-4/5 bottom-0 left-0 h-full object-cover bg z-50' src={img_parallax1_planet1}></img>
        <img className='absolute top-0 w-full h-full object-cover bg z-50' src={img_parallax1_planet2}></img>
        <img className='absolute bottom-0 right-0 w-4/5 h-full object-cover bg z-50' src={img_parallax1_planet3}></img>
        <img src={img_parallax1_asteroid1} className="layer h-full object-cover" style={{ transform: `rotate(-${scrollY1 / 50}deg)` }}></img>
        <img src={img_parallax1_asteroid2} className="layer h-full object-cover" style={{ transform: `rotate(-${scrollY1 / 50}deg)` }}></img>
        <img src={img_parallax1_asteroid3} className="layer h-full object-cover" style={{ transform: `rotate(-${scrollY1 / 50}deg)` }}></img>
        <img src={img_parallax1_asteroid4} className="layer h-full object-cover" style={{ transform: `rotate(-${scrollY1 / 50}deg)` }}></img>
        <img src={img_parallax1_spiral} className="spiral" style={{ transform: `rotate(${scrollY1 / 15}deg)` }} ></img>
        <img src={img_parallax1_chicken} className="chicken" style={{ transform: `rotate(-${(scrollY1 / 220)}deg)`, width: `${(300 + scrollY1 / 10)}px` }} ></img>
        {/* <img src={img_parallax1_chicken} className="chicken" style={{ transform: `rotate(-${(scrollY1 / 22) % 200}deg)`, width: `${(300 + scrollY1 / 10)}px` }} ></img> */}
      </div>
      <div className='story relative z-10 md:flex items-center' id='story'>
        <div className='block p-4 md:pl-0 md:flex items-center'>
          <img className='w-1/2 lg:w-1/2 xl:w-2/5 h-auto hidden lg:block' src={img_aso}></img>
          <img className='w-1/2 h-auto hidden md:block lg:hidden' src={img_aso_mid}></img>
          <div className='md:w-1/2 md:pr-10'>
            <div className='title '>story & context</div>
            <div className='content'>
              The universe, believed to have existed for approximately 13.8 billion years, hits a peak & unexpectedly decides, "You know what? Let's hit rewind and do it all over again!” This unexpected reset is known as “The Loop” <br /> Join the ASO crew to fight the evil forces behind this twisting phenomenon and bring a fresh new cosmic era on The Void Jumpers Adventure!
            </div>
            <div className='bottom'>93,016 billion light years of adventure</div>
          </div>
        </div>
      </div>
      {/* <div className='relative'>
          <div className='absolute left-0 top-24 w-2 h-2' id='characters'></div>
          <img className='w-full nft-all' src={img_all}></img>
        </div> */}
      <img className='w-full nft-all hidden lg:block' src={img_all} id='characters'></img>
      <img className='w-full nft-all md:hidden' src={img_all_sm} id='characters'></img>

      <div className='bg-[#FF00FE] p-4 pt-28 lg:pt-4'>
        <div className='block lg:flex justify-between items-center w-full lg:h-full lg:min-h-screen m-auto bg-[#FF00FE] max-w-[1200px]'>
          <div className='w-5/6 m-auto lg:w-2/5 lg:ml-12 lg:mr-12'><img src={img_nft_crew} className='w-full'/></div>
          <div className='w-full lg:w-3/5 mr-20 text-3xl font-nanum pb-40 lg:pb-10'>
            <h1 className='uppercase text-4xl text-white font-bangers mt-4 mb-3'>
              Stop the loop with the aso genesis collection!
            </h1>
            <p>The <span className='font-bold'>ASO PHASE 1</span> main heroes (and perhaps villains) are 8 unique characters that will come to life in the digital comic in phase 2. They are made up of 8 traits that range from hairstyle to weapons!</p>
            <p className='pt-6'>You will join them in <span className='font-bold'>PHASE 2</span> where your custom generated character will airdrop and share the narrative adventure in the ASO Universe</p>
            <p className='text-right'><a href={whitepaper} download className='no-underline hover:cursor-pointer'>know more...</a></p>
          </div>
        </div>
      </div>
      <img className='w-full nft-all hidden md:block lg:hidden' src={img_all_mid} id='characters'></img>

      {/* <div className='nft-avatar'>
          <div className='avatar'>
            <div className='title'>NFT AVATAR</div>
            <div className='relative'>
              <img className='w-full' src={img_avatar}></img>
              <img className='word' src={img_word}></img>
            </div>
            <div className='btn-mint'>mint now</div>
          </div>
          <div className='md:w-1/2 flex flex-col justify-center'>
            <div className='content'>
              Witness ASO heroes donning unique outfits,exhibiting distinct personalities,and leveraging their traits and characteristics to shape the narrative a concept reminiscent of character dynamics in role-playingvideo game
            </div>
            <div className='content'>
              The ASO characters are made up of  6 different unique traits plus background that you can see on your character throughout the adventure. These traits are:
            </div>
            <div className='attribute'>SKIN - HEAD - EYES - MOUTH - OUTFT - BACK ITEM - BACKGROUND</div>
          </div>
        </div> */}
      <div className='relative blackhole hidden lg:block' id='parallax2'>
        <img className='w-full h-full object-cover bg-main' src={img_parallax2_bg}></img>
        <img className='w-full h-full object-cover bg-mid' src={img_parallax2_still_bg}></img>
        {/* <img src={img_blackhole} className='w-full -mt-3'></img> */}
        <img className='layer' src={img_parallax2_layer} style={{ transform: `rotate(-${scrollY2 / 25}deg)` }}></img>
        <img className='pilot' src={img_parallax2_pilot} style={{ transform: `rotate(${scrollY2 / 50 + 6}deg)` }} ></img>
        <img className='cable1' src={img_parallax2_cable1}></img>
        <img className='cable2' src={img_parallax2_cable2} style={{ transform: `rotate(${scrollY2 / 50}deg)` }}></img>
        {/* <div className='to-unknown'>to the unknown...</div> */}
      </div>
      <div className='relative block lg:hidden' id='parallax2'>
        <img className='w-full h-full object-cover' src={img_parallax2_still_sm}></img>
      </div>
      <div className='roadmap relative'>
        <div className='head m-auto w-fit pb-8'>
          <div className='title relative'>
            <img src={img_roadmap_bg}></img>
            <div className=''>road map</div>
          </div>
        </div>
        <div className='phase-description pb-12 uppercase'>Join the adventure and be part of the craziest blockchain journey!</div>
        <div className='block xl:flex max-w-7xl m-auto'>
          <div className='w-4/5 xl:w-1/2 relative phase1 xl:m-4 m-auto max-w-2xl'>
            <div className='title'>
              <img src={img_phase1_title_bg}></img>
              <div className='flex items-center'>PHASE 1 <span className='pl-4'>the genesis</span></div>
            </div>
            <div className='content'>
              <img className='w-full bg' style={{ height: phase1Height }} src={img_phase1_content_bg}></img>
              <div className='absolute w-full top-0' ref={phase1Ref}>
                <div className='bio'>Minting of 3,333 genesis collection.</div>
                <div className='pt-5 text-center'>Made up of <span className='underline uppercase'>8 main characters</span> who have the destiny of the universe in their hands! These NFTs will become your gateway to unlocking multiple phases of this cosmic odyssey.</div>
              </div>
              <img src={img_features1} className='features'></img>
            </div>
            <div className='w-fit m-auto feature-content'>
              <div>- Character reveal</div>
              <div>- story reveal</div>
              <div>- Unlockable content </div>
            </div>
          </div>
          <div className='w-10'></div>
          <div className='w-4/5 xl:w-1/2 relative phase2 xl:m-4 m-auto mt-20 max-w-2xl'>
            <div className='title'>
              <img src={img_phase2_title_bg}></img>
              <div className='flex items-center text-center'>PHASE 2 <span className='pl-4'>the journey</span></div>
            </div>
            <div className='content'>
              <img className='w-full bg' style={{ height: phase2Height }} src={img_phase2_content_bg}></img>
              <div className='absolute w-full top-0' ref={phase2Ref}>
                <div className='bio'>protagonists collection<br></br> Airdroped to ASO holders</div>
                <div className='pt-5 text-center'>Your <span className='underline'>NFTs protagonist</span> will join the main characters in a digital intergalactic adventure with all its traits and characteristics. You will be able to be part of the ASO narrative graphic adventure!</div>
              </div>
              <img src={img_features2} className='features'></img>
            </div>
            <div className='w-fit m-auto feature-content'>
              <div>- Digital comic with <br /><span className='underline'>generative narrative elements!</span></div>
              <div>- Unlockable prizes</div>
              <div>- Merch stuff</div>
              <div>- more to come</div>
            </div>
          </div>
        </div>
      </div>
      <div className='crew'>
        <div className='top'></div>
        <div className='head m-auto w-fit pb-8 pt-12'>
          <div className='relative'>
            <img src={img_roadmap_bg}></img>
            <div className='title'>The team</div>
          </div>
        </div>
        {/* <div className='title'>The team</div> */}
        <div className='block lg:flex flex-wrap justify-between w-full m-auto md:p-32'>
          <div className='w-full lg:w-1/2 xl:w-1/4 flex flex-col items-center p-4 pb-10'>
            <div className='ellipse relative'>
              <a href='https://twitter.com/chicken_Lordxx' target='_blank'>
                <img className='w-full' src={img_chicken}></img>
              </a>
              <div className='bio'>
                <img src={img_crew_title1}></img>
                <div className='name'>chicken lord</div>
                <div className='desc'>Creator-lead - artist</div>
              </div>
            </div>
            <div className='description'>Creative Builder,  Digital Illustrator, Design Warrior</div>
          </div>
          <div className='w-full lg:w-1/2 xl:w-1/4 flex flex-col items-center p-4 pb-10'>
            <div className='ellipse relative add'>
              <a href='https://twitter.com/Geminhighi' target='_blank'>
                <img className='w-full' src={img_gem}></img>
              </a>
              <div className='bio'>
                <img src={img_crew_title2}></img>
                <div className='name'>GΞMINIHIGH</div>
                <div className='desc'>Founder - Marketing</div>
              </div>
            </div>
            <div className='description'>Intergalactic Explorer, Community Builder, Space Pioneer.</div>
          </div>
          <div className='w-full lg:w-1/2 xl:w-1/4 flex flex-col items-center p-4 pb-10'>
            <div className='ellipse relative add'>
              <a href='https://twitter.com/Ikiag1' target='_blank'>
                <img className='w-full h-full' src={img_ikiag}></img>
              </a>
              <div className='bio blank'>
                <img src={img_crew_title1}></img>
                <div className='name'>Denis</div>
                <div className='desc'>development</div>
              </div>
            </div>
            <div className='description'>IT Virtuoso, Farm Whisperer, And Office Ninja</div>
          </div>
          <div className='w-full lg:w-1/2 xl:w-1/4 flex flex-col items-center p-4 pb-10'>
            <div className='ellipse relative add'>
              <a href='https://twitter.com/Havoc_8_8' target='_blank'>
                <img className='w-full' src={img_havoc}></img>
              </a>
              <div className='bio'>
                <img src={img_crew_title1}></img>
                <div className='name'>HAVOK</div>
                <div className='desc'>production - advisor</div>
              </div>
            </div>
            <div className='description'>LabNinety<span>1</span> founder, Chronic Entrepreneur, Degen at heart</div>
          </div>
        </div>
      </div>






      <div className='contact'>
        <div className='content'>
          <div className='m-auto pt-14'>
            <img className='m-auto' src={img_logo_white}></img>
          </div>
          <div className='title'>CONTACT form?</div>
          <div className='description'>Contact us through our networks</div>
          {/* <input type='text' className='input'></input>
            <input type='text' className='input'></input>
            <textarea className='input'></textarea>
            <div className='flex flex-row-reverse'>
              <div className='send'>send</div>
            </div> */}
          <div className='flex justify-center pt-8'>
            <a href='https://twitter.com/ASOdysseyNFT' target='_blank'><img className='w-28 p-4' src={img_twitter}></img></a>
            <a href='https://discord.gg/gHZabsvcXk' target='_blank'><img className='w-28 p-4' src={img_discord}></img></a>
            <a href='https://t.me/ASONFT3333' target='_blank'><img className='w-28 p-4' src={img_telegram}></img></a>
          </div>
        </div>
      </div>
      <div className='footer bg-white'>
        another space odyssey 2024 all rights reserved
      </div>
    </div>
  );
}

export default Main;
