import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import ScrollToButton from './ScrollToButton';

import img_logo from '../assets/images/logo.png';
import img_twitter from '../assets/images/twitter.png';
import img_discord from '../assets/images/discord.png';
import img_telegram from '../assets/images/telegram.png';
import img_globe from '../assets/images/globe.png';

import React, { useState } from 'react';


export const Header = (props) => {

    const [isExpanded, setExpanded] = useState(false);
    const [showDropDown, setShowDropDown] = useState(false);

    return (
        <>
            <div className='header flex justify-between p-5 items-center'>
                <div className='logo h-full'>
                    <a href='/'><img className='h-full' src={img_logo}></img></a>
                </div>
                <div className='flex items-center menu-items'>
                    
                    <ScrollToButton toId="trailer"><div className='item'>trailer</div></ScrollToButton>
                    <ScrollToButton toId="story"><div className='item'>story</div></ScrollToButton>
                    <ScrollToButton toId="characters"><div className='item'>characters</div></ScrollToButton>
                    <ScrollToButton toId="nfts"><div className='item'>nfts</div></ScrollToButton>
                    
                    <div className='pl-4'>
                        <a href='https://twitter.com/ASOdysseyNFT' target='_blank'><img className='w-7' src={img_twitter}></img></a>
                    </div>
                    <div className='pl-4'>
                        <a href='https://discord.gg/gHZabsvcXk' target='_blank'><img className='w-7' src={img_discord}></img></a>
                    </div>
                    <div className='pl-4 pr-4'>
                        <a href='https://t.me/ASONFT3333' target='_blank'><img className='w-7' src={img_telegram}></img></a>
                    </div>
                </div>
                {!showDropDown ? (
                    <FontAwesomeIcon className='hamburger' color='white' icon={faBars} onClick={() => setShowDropDown(true)} />
                ) : (
                    <FontAwesomeIcon className='hamburger' color='white' icon={faXmark} onClick={() => setShowDropDown(false)} />
                )}
            </div>
            {showDropDown ? (
                <div className="ham-dropdown">
                    <div className='item'>trailer</div>
                    <div className='item'>story</div>
                    <div className='item'>characters</div>
                    <div className='item'>nfts</div>
                </div>
            ) : (
                ""
            )}
        </>

    )
}